import React from 'react'
import NavBar from '../components/NavBar'


const educators = () => {
  return (
    <div>
        <NavBar />
        <div>educators</div>
    </div>
  )
}

export default educators